import React, { useState } from 'react';
import Pages from '../data/page_data';
import Box from '@mui/material/Box';
import HeaderLogo from '../images/mm_logo_square_black.jpeg';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { Link, useLocation } from 'react-router-dom';
import NavButton from './NavButton';
import { ListItemIcon, ListItemText } from '@mui/material';
import theme from '../theme/theme_config';

function Navigation() {

  let location = useLocation();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" color='primary'>
        <Box sx={{ px: 2, py: 1 }}>
          <Toolbar disableGutters>
            <Avatar
              src={HeaderLogo}
              variant="rounded"
              sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, height: 74, width: 59 }}
              component={Link}
              to="/"
            />
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon sx={{color:theme.sitenav.text.primary}}/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  '&:hover': {
                    color: theme.palette.secondary.main
                  }
                }}
              >
                {Pages.map((page, i) => (
                  page.link !== location.pathname && (
                    <MenuItem key={page.id} onClick={handleCloseNavMenu} component={Link} to={page.link}>
                      <ListItemIcon>
                        {page.linkIcon}
                      </ListItemIcon>
                      <ListItemText>
                        <Typography>{page.linkText}</Typography>
                      </ListItemText>
                    </MenuItem>
                  )
                ))}
              </Menu>
            </Box>
            <Box sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}>
              <Avatar
                src={HeaderLogo}
                variant="rounded"
                sx={{ display: { xs: 'flex', md: 'none' }, height: 74, width: 59 }}
                component={Link}
                to="/"
              />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {Pages.map((page, i) => (
                page.id > 1 && (
                  <NavButton
                    key={page.id}
                    onClick={handleCloseNavMenu}
                    className={`/${location.pathname.split('/')[1]}` === page.link ? 'Mui-active' : "ripple-black" }
                    sx={{
                      mx: 2,
                      display: 'block',
                    }}
                    disabled={ location.pathname === page.link }
                    component={Link}
                    to={page.link}
                  >
                    {page.linkText}
                  </NavButton>
                )
              ))}
            </Box>
            {/* <Box>
              <Button size="medium" target="_blank" href='https://www.crowdfunder.co.uk/p/music-matters-hants' variant='contained' color="success" startIcon={<RedeemIcon />}>
                Donate
              </Button>
            </Box> */}
          </Toolbar>
        </Box>
      </AppBar>
    </React.Fragment>
  )
}

export default Navigation;