import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { getPage } from '../functions/text_functions';
import { PageInfoType } from '../data/page_data';
import galleryData from '../data/hp_gallery';
import Paper from '@mui/material/Paper';
import { Card, CardMedia, Stack, Typography } from '@mui/material';
import { InfoCard, Gallery, TextList } from '../components';


export default function Home() {

  const [pageInfo, setPageInfo] = useState<PageInfoType | null>(null);

  useEffect(() => {
    const setupPage = async () => {
      const response = await getPage(1);
      setPageInfo(response);
    }

    setupPage();
  }, []);
  return (
    <Grid container justifyContent="space-evenly">
      <Stack direction='column'>

      <Card sx={{ boxShadow: 'none', border: 'none' }}>
        <CardMedia
          component="iframe"
          src="https://player.vimeo.com/video/1007832013"
          title="Victorious Festival Highlights"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          sx={{height: {xs:200, sm:350, md:500, lg:700}}}
        />
      </Card>

        <Gallery {...galleryData} />
        <Grid sx={{ pt: 2 }} width="md" container direction="row" justifyContent="space-evenly" alignItems="center">
          {
            pageInfo?.cards.map((card, index) => {
              return <InfoCard key={index} title={card.title} copy={card.copy} image={card.image} icon={card.icon} button={card.button} />
            })
          }
        </Grid>
        {
          (pageInfo?.textListItems !== undefined && pageInfo?.textListItems.length > 0) &&
          <Stack direction="column" justifyContent="space-evenly" alignItems="center">
            <Typography variant="h4" sx={{ pt: 4 }}>Latest Updates</Typography>
            <Paper elevation={3} sx={{ py: 2, m: 4 }} >
              <TextList collection={pageInfo?.textListItems} />
            </Paper>
          </Stack>
        }
      </Stack >
    </Grid >
  )
}