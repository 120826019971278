import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Root, Home, Contact, Testimonials, ErrorPage, About, WhatsOnOffer, OurPatrons, AboutUs } from '../pages';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [{
            path: "/",
            element: <Home />
        },
        {
            path: "/about",
            element: <About />,
            children: [
                {
                    path: 'about-us',
                    element: <AboutUs />
                },
                {
                    path: 'whats-on-offer',
                    element: <WhatsOnOffer />
                },
                {
                    path: 'our-patrons',
                    element: <OurPatrons />
                }
            ]
        },
        {
            path: "/contact",
            element: <Contact />
        }
        ,
        {
            path: "/testimonials",
            element: <Testimonials />
        }
    ]}
]);

export default function AppRoutes() {

    //let pathname = usePathname();
    //const history = useHistory();

    return (
        <RouterProvider router={router} />
    )
}