import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { PageInfoTypeSection } from '../data/page_data';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { useMediaQuery, useTheme } from '@mui/material';

function MixedContentSection(props: MixedContentSectionProps){

  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {sectionContent, flexDirection} = props;
  const defaultValues = {
    flexDirection: "row",
  }

  return (
    <Grid container flexDirection={flexDirection ?? defaultValues.flexDirection}>
      <Grid item xs={12} md={6} alignSelf="center" justifySelf="center" sx={{px: {xs:3, md:10}, py:{xs:3, overflow: "hidden"}}}>
        {/* This needs to be conditional if the title has a value! */}
        {sectionContent.title && 
        <Typography component="h2" textAlign={isMobile ? 'left' : flexDirection === "row" ? 'right' : 'left'} sx={{fontWeight:700, fontSize:24, mb: {xs: 1, md: 4}}}>
          {sectionContent.title}
        </Typography>
        }
        <Typography component="div" textAlign={isMobile ? 'left' : flexDirection === "row" ? 'right' : 'left'} variant="body1" position="relative" >
        { sectionContent.showQuotes && (
          <FormatQuoteIcon sx={{
            fontSize: 60,
            color: 'GrayText', 
            position: 'absolute', 
            top: -35, 
            left: -55, 
            opacity: 0.4,
            transform: 'scaleX(-1)'
            }}
          />
        )}
          {sectionContent.copy}
        { sectionContent.showQuotes && (
          <FormatQuoteIcon sx={{fontSize: 60, color:'GrayText', position:'absolute', bottom: -35, right: -35, opacity: 0.4}}/>
        )}
        </Typography>
        
      </Grid>
      <Grid item xs={12} md={6} sx={{ borderRadius: 4 }} overflow="hidden" justifyItems="center">
        {(sectionContent.image !== null && sectionContent.image !== undefined) &&
          <img 
            alt="Section" 
            height="100%" 
            width="100%"
            src={sectionContent?.image} 
            loading="lazy"
          />
        }
      </Grid>
    </Grid>
  )
}

export type MixedContentSectionProps = {
  sectionContent: PageInfoTypeSection;
  flexDirection?: any;
};

export default MixedContentSection