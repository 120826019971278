import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Grid, MenuItem, Pagination as PagingUI, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';

export type PaginationType = {
    collection: any;
    options?: PagingPropsType;
    children: any;
}

export type PagingPropsType = {
    itemsPerPage?: number;
    allowItemsPerPage?: boolean;
    showFirstButton?: boolean;
    showLastButton?: boolean;
    fillEmptySpace?: boolean;
}

const defaultOptions = {
    itemsPerPage: 5,
    allowItemsPerPage: false,
    showFirstButton: false,
    showLastButton: false,
    fillEmptySpace: false,
}

function Pagination(props: PaginationType) {

    const { children, collection, options } = props;

    const [pagingProps, setPagingProps] = useState<PagingPropsType>({
        itemsPerPage: options?.itemsPerPage ?? defaultOptions.itemsPerPage,
        allowItemsPerPage: options?.allowItemsPerPage ?? defaultOptions.allowItemsPerPage,
        showFirstButton: options?.showFirstButton ?? defaultOptions.showFirstButton,
        showLastButton: options?.showLastButton ?? defaultOptions.showLastButton,
        fillEmptySpace: options?.fillEmptySpace ?? defaultOptions.fillEmptySpace,
    });
    const [activePage, setActivePage] = useState<number>(1);
    const [pageCollection, setPageCollection] = useState<any>({});
    const [showItems, setShowItems] = useState<any>({});
    const [pageCount, setPageCount] = useState<number>(0);

    const filterListItems = useCallback((from: number, to: number) => {
        var itemsToShow = pageCollection.slice(from, to);
        var requiredItemCount = pagingProps.itemsPerPage ?? 0;
        
        if ( pagingProps.fillEmptySpace && itemsToShow.length < requiredItemCount ){
            // Get the missing number
            var numberToAdd = requiredItemCount - itemsToShow.length;
            var emptyObj: any = {};

            for (const prop of Object.getOwnPropertyNames(itemsToShow[0])) {
                switch(typeof itemsToShow[0][prop]){
                    case 'string':
                        emptyObj[prop] = "";
                        break;
                    case 'number':
                        emptyObj[prop] = -1;
                        break;
                    case 'boolean':
                        emptyObj[prop] = false;
                        break;
                    case 'object':
                        emptyObj[prop] = {};
                        break;
                    default:
                        emptyObj[prop] = "";
                        break;
                }
            }
            for (let i = 0; i < numberToAdd; i++){
                itemsToShow.push(emptyObj);
            }
        }
        setShowItems(itemsToShow);
    },[pageCollection, pagingProps.fillEmptySpace, pagingProps.itemsPerPage]);

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setActivePage(value);
    };

    const handleChangeIPP = (event: SelectChangeEvent) => {
        const tempIPP: unknown = event.target.value;
        setPagingProps({...pagingProps, itemsPerPage: tempIPP as number});
    };

    const stringIPP = (input: number|undefined): string => {
        var output: unknown = input;
        if ( output === undefined ){
            output = "";
        }
        return output as string;
    };

    useEffect(() => {
        const setPageProps = async() => {
            setPageCollection(collection);
            var tempPerPage: unknown = pagingProps.itemsPerPage;
            var tempPerPageNum: number = tempPerPage as number;
            const tempCount = Math.ceil(collection.length / tempPerPageNum);
            if ( activePage > tempCount ){
                setActivePage(tempCount);
            }
            setPageCount(tempCount);
        }
        const getListItems = async() => {
            var from = (activePage - 1) * (pagingProps?.itemsPerPage || 0);
            var to = (from) + (pagingProps?.itemsPerPage || 0);
            filterListItems(from, to);
        }
        setPageProps()
        .then(() => {
            if ( pageCollection.length > 0 ){
                getListItems();
            }
        });
    }, [pageCollection, pagingProps.itemsPerPage, collection, activePage, filterListItems]);

    return (
        <Box>
            {showItems.length > 0 && React.cloneElement(children, {collection: showItems})}
            {pageCollection.length > 0 ? (
            <Grid container justifyContent="center" sx={{py: 2}}>
                {(pageCount > 1 || pageCollection.length >= defaultOptions.itemsPerPage) && (
                    <Stack direction="column" spacing={0}>
                        <PagingUI
                            count={pageCount}
                            page={activePage}
                            onChange={handleChangePage}
                            shape="rounded"
                            variant="outlined"
                            showFirstButton={(pagingProps.showFirstButton && pageCount > 5) ? true : false}
                            showLastButton={(pagingProps.showLastButton && pageCount > 5) ? true : false}
                        />
                        {pagingProps.allowItemsPerPage && (
                            <Stack direction="row" spacing={0}>
                                <Typography alignSelf="center" variant="caption">Showing</Typography>
                                <Select
                                    value={stringIPP(pagingProps.itemsPerPage)}
                                    onChange={handleChangeIPP}
                                    variant="outlined"
                                    sx={{p:0, height: 32, m: 1}}
                                >
                                    <MenuItem value={defaultOptions.itemsPerPage}>
                                        <strong>{defaultOptions.itemsPerPage}</strong>
                                    </MenuItem>
                                    {pageCollection.length > 9 && <MenuItem value={10}>10</MenuItem>}
                                    {pageCollection.length > 19 && <MenuItem value={20}>20</MenuItem>}
                                    {pageCollection.length > 29 && <MenuItem value={30}>30</MenuItem>}
                                    {(pageCollection.length > defaultOptions.itemsPerPage && pageCollection.length < 10) && <MenuItem value={pageCollection.length}>{pageCollection.length}</MenuItem>}
                                </Select>
                                <Typography alignSelf="center" variant="caption">items per page</Typography>
                            </Stack>
                        )}
                    </Stack>
                )}
            </Grid>
            ): null }
        </Box>
    )

}

export default Pagination;