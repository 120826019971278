import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export type SocialLinkType = {
    id: number;
    enabled: boolean;
    service: keyof ISocialTypes;
    icon: React.ReactNode;
    link: string;
    tooltipText?: string;
}

export interface ISocialTypes {
    Facebook: null,
    Twitter: null,
    Instagram: null,
    WhatsApp: null,
    Tiktok: null
}

const TikTokIcon = ({ color = "#000000" }) => {
    return (
      <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="25px"
        height="25px"
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    );
  };

const socialLinks: SocialLinkType[] = [
    {
        id: 1,
        enabled: true,
        service: 'Facebook',
        icon: <FacebookIcon fontSize="medium" />,
        link: 'https://www.facebook.com/profile.php?id=100089868913407',
        tooltipText: 'See us on Facebook',
    },
    {
        id: 2,
        enabled: false,
        service: 'Twitter',
        icon: <TwitterIcon fontSize="medium"/>,
        link: 'https://www.twitter.com',
        tooltipText: 'Join us on Twitter'
    },
    {
        id: 3,
        enabled: false,
        service: 'Instagram',
        icon: <InstagramIcon fontSize="medium"/>,
        link: 'https://www.instagram.com',
        tooltipText: 'Join us on Instagram'
    },
    {
        id: 4,
        enabled: true,
        service: 'WhatsApp',
        icon: <WhatsAppIcon fontSize="medium"/>,
        link: 'https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D%252B447340987129%26fbclid%3DIwAR22aWC6GGHlqfJOXQ1i0Z3zlyrnOZoud-LgRedDXZA5jOwfaZkHqa212wc&h=AT1-xiN9MaCf8Vc8sX0dG39RO7-a8kWXpk4JWwtQ-bJk1QS8Xf3HVxmgsDO8j472c7ZhLt0DbnS7VBMfDkfUHNnxl_aPwvL7oqKUK5Dg25zAByz198qC2ohLy-ufAUR1wdfvNGuYURH-8enarN8',
        tooltipText: 'Join us on WhatsApp'
    },
    {
        id: 5,
        enabled: true,
        service: 'Tiktok',
        icon: <TikTokIcon/>,
        link: 'https://www.tiktok.com/@thismusicmatters?fbclid=IwAR2jSkPrhCrXViUV6hVDrBrkEf9cDrWrKNB04j0uiaKoRcvUR50OVVVpcQg',
        tooltipText: 'Join us on Tiktok'
    }
]

export default socialLinks;