import React from 'react';
import Grid from '@mui/material/Grid';
import { useChildInfo } from './About';
import { PageInfoTypeSection } from '../data/page_data';
import Typography from '@mui/material/Typography';
import Profile from '../components/Profile';

export default function OurPatrons() {
  const { childInfo } = useChildInfo();
  
  return (
    childInfo && (
      <Grid item container>
      <Typography variant="h4" flexGrow="1" sx={{ py: {sm: 1, md: 2} }} textAlign="center">{childInfo.title}</Typography>
        {childInfo !== null && childInfo.sections.map((section: PageInfoTypeSection, index: number) => {
          return <Profile key={section.id} sectionContent={section} />
         })}
      </Grid>
    )
  )
}