import { GalleryProps } from "../components/Gallery"
import BWStudio from '../images/hp_gallery/black_white_full_studio.webp';
import BWDesk from '../images/hp_gallery/black_white_desk.webp';
import BWDecks from '../images/hp_gallery/black_white_decks.webp';
import BWMic from '../images/hp_gallery/black_white_mic.webp';
import TMMPoster from '../images/hp_gallery/promo_image_20240103.jpg';

const galleryData: GalleryProps = {
  title: "",
  images:
    [
      {
        image: TMMPoster,
        title: 'TMM Promo Poster',
        contain: false,
        rows: {
          desktop: 3,
          mobile: 2
        },
        cols: {
          desktop: 2,
          mobile: 1
        }
      },
      {
        image: BWDesk,
        title: 'Working hard',
        contain: false,
        rows: {
          desktop: 1,
          mobile: 1
        },
        cols: {
          desktop: 2,
          mobile: 1
        }
      },
      {
        image: BWStudio,
        title: 'In the studio',
        contain: false,
        rows: {
          desktop: 1,
          mobile: 1
        },
        cols: {
          desktop: 1,
          mobile: 1
        }
      },
      {
        image: BWDecks,
        title: 'Mixing decks',
        contain: false,
        rows: {
          desktop: 1,
          mobile: 1
        },
        cols: {
          desktop: 1,
          mobile: 1
        }
      },
      {
        image: BWMic,
        title: 'Mic test',
        contain: false,
        rows: {
          desktop: 1,
          mobile: 1
        },
        cols: {
          desktop: 2,
          mobile: 1
        }
      }
    ]
}

export default galleryData;