import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PageInfoTypeSection } from '../data/page_data';

function Profile(props: ProfileProps) {

  const {sectionContent} = props;

  return (
    <Stack direction={{xs:"column", sm:"row"}} sx={{pt:5}}>
      <Box minWidth="200px" maxHeight="230" sx={{mr:10}}>
      {(sectionContent.image !== null && sectionContent.image !== undefined) &&
        <img 
          alt="Section" 
          src={sectionContent?.image}
          loading="lazy"
          style={{borderRadius:"20px"}}
          />
      }
      </Box>
      <Stack direction="column">
        <Typography variant='h5' sx={{pt:2}}>{sectionContent.title}</Typography>
        <Typography variant='subtitle2' sx={{pt:1}}>{sectionContent.copy}</Typography>
      </Stack>
    </Stack>
  )
}

export type ProfileProps = {
  sectionContent: PageInfoTypeSection;
};

export default Profile