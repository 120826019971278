import React from 'react';
import Grid from '@mui/material/Grid';
import { useChildInfo } from './About';
import Typography from '@mui/material/Typography';
import Profile from '../components/Profile';

export default function AboutUs() {
  const { childInfo } = useChildInfo();

  return (
    childInfo && (
      <Grid item container sx={{mx:1}}>
        <Typography variant="h4" flexGrow="1" sx={{ py: {sm: 1, md: 2} }} textAlign="center">{childInfo.title}</Typography>
        {childInfo.subtitle}
        <Profile key={childInfo.sections[0].id} sectionContent={childInfo.sections[0]} />
      </Grid>
    )
  )
}