import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Alert, Box, Button, Card, CardMedia, List, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { getPage } from '../functions/text_functions';
import { PageInfoType } from '../data/page_data';
import GuitarImage from '../images/Guitar_Being_Played_wide.webp';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

export default function Contact() {

  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [formData, setFormData] = useState<any | null>({
    userFirstName: "",
    userSurname: "",
    emailAddress: "",
    message: ""
  });
  const [errors, setErrors] = useState<any>({
    userFirstName: {
      hasError: false,
      helperText: "Hi, we're Music Matters, what's your name?",
      errorText: "You haven't introduced yourself yet!",
    },
    userSurname: {
      hasError: false,
      helperText: "And your last name, so we can pick you out in a crowd!",
      errorText: "Please let us know your Surname",
    },
    emailAddress: {
      hasError: false,
      helperText: "And your email address so we can get back to you. Don't worry, we don't store this anywhere!",
      errorText: "Don't forget your email address otherwise we won't be able to get back to you!",
    },
    message: {
      hasError: false,
      helperText: "And finally, what would you like to ask us?",
      errorText: "We'd really like to know how we can help...",
    },
  })

  const [submitResponse, setSubmitResponse] = useState<string>("");
  const [snackbarOpen, setsnackbarOpen] = useState<boolean>(false);
  const [pageInfo, setPageInfo] = useState<PageInfoType|null>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [event.target.name]: event.target.value});
    setErrors({
      ...errors,
      [event.target.name]: {
        hasError: false,
        errorText: errors[event.target.name].errorText,
        helperText: errors[event.target.name].helperText
      }
    });
  }

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): boolean => {
    if (event.target.value === ""){
      setErrors({
        ...errors,
        [event.target.name]: {
          hasError: true,
          errorText: errors[event.target.name].errorText,
          helperText: errors[event.target.name].helperText
        }
      });
    }else{
      setErrors({
        ...errors,
        [event.target.name]: {
          hasError: false,
          errorText: errors[event.target.name].errorText,
          helperText: errors[event.target.name].helperText
        }
      });
    }
    return false;
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setsnackbarOpen(false);
  };

  const submitForm = async () =>{
    let payload = formData;
    let requestOptions = {method: "POST", headers: {'content-type': 'application/json'}, body: JSON.stringify(payload)}
    let response = await fetch("https://www.thismusicmatters.co.uk/email/", requestOptions);
    let data:any = await response.json();
    setSubmitResponse(data.message);
    setsnackbarOpen(true);
    setFormData({
      userFirstName: "",
      userSurname: "",
      emailAddress: "",
      message: ""
    });
    setErrors({
      userFirstName: {
        hasError: false,
        helperText: "Hi, we're Music Matters, what's your name?",
        errorText: "You haven't introduced yourself yet!",
      },
      userSurname: {
        hasError: false,
        helperText: "And your last name, so we can pick you out in a crowd!",
        errorText: "Please let us know your Surname",
      },
      emailAddress: {
        hasError: false,
        helperText: "And your email address so we can get back to you. Don't worry, we don't store this anywhere!",
        errorText: "Don't forget your email address otherwise we won't be able to get back to you!",
      },
      message: {
        hasError: false,
        helperText: "And finally, what would you like to ask us?",
        errorText: "We'd really like to know how we can help...",
      },
    })
  }

  const getContactBullets = () => {
    const bullets = pageInfo?.sections.filter((item, idx) => item.id > 2);
    var content: any;
    if ( pageInfo && bullets ){
      content = bullets.map((item) => {
        return (
          <ListItemButton key={item.id}>
            <ListItemIcon>
              <MusicNoteIcon />
            </ListItemIcon>
            <ListItemText>
              {item.copy}
            </ListItemText>
          </ListItemButton>
        )
      });
    }else{
      content = null;
    }
    return content;
  }

  const checkFormIsValid = ():boolean => {
    return formData.userFirstName && 
      formData.userSurname && 
      formData.emailAddress && 
      formData.message;
  }

  useEffect(() => {
    const setupPage = async () => {
      const page = await getPage(5);
      setPageInfo(page);
    }

    setupPage();
  }, []);

  return (
    <Grid container sx={{mt: 0}}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        {submitResponse}
        </Alert>
      </Snackbar>
      <Grid item md={5} sx={{ px: 4 }}>
        <Card sx={{ minHeight: '100%'}}>
            <CardMedia
              component="img"
              height="250"
              image={GuitarImage}
              alt="Guitar and piano"
              sx={{ display: isMobile ? 'none' : 'block'}}
            />
            <Box sx={{px:2, pt:2}}>
              <Typography alignContent="center" variant="h5" lineHeight="1.1em" component="div">
                Get in touch with us to discuss...
              </Typography>
            </Box>
          <List>
            {getContactBullets()}
          </List>
        </Card>
      </Grid>
      <Grid item xs={12} md={7} pt={{ xs: 3, md: 0 }} sx={{ px: 4, display: 'block'}} alignItems="stretch">
        <Stack direction="column" spacing={1} component={Paper} padding={2}>
          <Typography variant="h5" component="h3">Email us...</Typography>
          <Typography variant="subtitle1">{pageInfo?.sections[0].copy}</Typography>
          <Typography sx={{ pb: 1 }} variant="subtitle1">{pageInfo?.sections[1].copy}</Typography>
          <Stack direction={{ sm: "column", md: "row" }} spacing={2}>
            <TextField
              required
              name="userFirstName"
              label="First Name"
              sx={{ pb: 2 }}
              size="small"
              fullWidth
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              value={formData.userFirstName}
              error={errors.userFirstName.hasError}
              helperText={errors.userFirstName.hasError ? errors.userFirstName.errorText : errors.userFirstName.helperText}
            />
            <TextField
              required
              name="userSurname"
              label="Surname"
              sx={{ pb: 2 }}
              size="small"
              fullWidth
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              value={formData.userSurname}
              error={errors.userSurname.hasError}
              helperText={errors.userSurname.hasError ? errors.userSurname.errorText : errors.userSurname.helperText}
            />
          </Stack>
          <TextField
            required
            name="emailAddress"
            label="Email Address"
            sx={{pb: 2}}
            size="small"
            fullWidth
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={formData.emailAddress}
            error={errors.emailAddress.hasError}
            helperText={errors.emailAddress.hasError ? errors.emailAddress.errorText : errors.emailAddress.helperText}
          />
          <TextField
            name="message"
            label="Message to Music Matters"
            multiline
            rows={6}
            fullWidth
            sx={{ pb: 2 }}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={formData.message}
            error={errors.message.hasError}
            helperText={errors.message.hasError ? errors.message.errorText : errors.message.helperText}
          />
          <Grid item container justifyContent="flex-end" flexGrow={1} alignContent="flex-end">
            <Button disabled={!checkFormIsValid()} onClick={submitForm} variant="contained">Submit</Button>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  )
}