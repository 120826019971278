import React from 'react';
import Pages, { PageInfoType } from "../data/page_data";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Box from '@mui/material/Box';

async function getPage(pageId: number): Promise<PageInfoType> {

    const pageObj: PageInfoType[] = Pages;

    const page = pageObj.filter((page) => page.id === pageId);

    return page[0];
}



function copyWithQuotes(copy: React.ReactNode) {
    return (
        <Box sx={{ position: 'relative'}}>
            <FormatQuoteIcon sx={{ fontSize:30, color:'GrayText', position:'absolute', top: -10, left: -25, opacity:0.4, transform: 'scaleX(-1)' }} />
                {copy}
            <FormatQuoteIcon sx={{fontSize: 30, color:'GrayText', position:'absolute', bottom: -10, right: -20, opacity: 0.4}}/>
        </Box>
    );
}

export {getPage, copyWithQuotes}