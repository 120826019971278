import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';

function SiteHero(props: SiteHeroProps){

    const {title, subtitle, bgColour} = props;

    const bgImage1 = () => {
        return `<svg viewBox='0 0 1440 320' xmlns='http://www.w3.org/2000/svg'><path fill='${bgColour.replace('#', '%23')}' fill-opacity='0.4' d='M 0 224.727 L 60 235.427 C 120 245.727 240 267.727 360 278.027 C 480 288.727 600 288.727 720 267.427 C 840 245.727 965.285 223.054 1086.386 222.549 C 1214.368 222.015 1314.412 228.025 1375.21 244.9 L 1441.596 269.357 L 1440 0 L 1380 0 C 1320 0 1200 0 1080 0 C 960 0 840 0 720 0 C 600 0 480 0 360 0 C 240 0 120 0 60 0 L 0 0 L 0 224.727 Z'/></svg>`;
    }
    const bgImage2 = () => {
        return `<svg viewBox='0 0 1440 320' xmlns='http://www.w3.org/2000/svg'><path fill='${bgColour.replace('#', '%23')}' fill-opacity='0.5' d='M -0.798 203.479 C -1.262 202.922 122.901 160.553 203.725 157.764 C 312.461 162.737 320 175.873 472.816 200.318 C 625.632 224.763 803.962 240.36 964.789 239.829 C 1126.385 239.295 1239.291 183.113 1320.089 164.902 C 1368.989 151.858 1440.875 155.846 1440.798 155.869 L 1440 0 L 1360 0 C 1280 0 1120 0 960 0 C 800 0 640 0 480 0 C 320 0 160 0 80 0 L 0 0 L -0.798 203.479 Z'/></svg>`;
    }
    const bgImage3 = () => {
        return `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'><path fill='${bgColour.replace('#', '%23')}' fill-opacity='0.4' d='M -3.193 174.438 C -0.337 165.869 88.26 165.054 84.745 175.599 C 85.486 174.993 82.157 173.134 181.996 198.43 C 298.657 227.988 318.226 221.143 438.226 202.943 C 558.226 184.4 697.908 151.125 816.586 173.697 C 985.278 205.781 1195.477 132.107 1255.477 136.978 C 1278.215 122.149 1466.169 144.396 1440 161.463 L 1440 0 L 1380 0 C 1320 0 1200 0 1080 0 C 960 0 840 0 720 0 C 600 0 480 0 360 0 C 240 0 120 0 60 0 L 0 0 L -3.193 174.438 Z'></path></svg>`;
    }

    return (
        <Box
            className="hero-bg"
            sx={{
                pt: 2,
                pb: 4,
                mb: {xs: 2, md: 0},
                backgroundImage: `url("data:image/svg+xml, ${bgImage1()}"), url("data:image/svg+xml, ${bgImage2()}"), url("data:image/svg+xml, ${bgImage3()}")`
            }}
        >
            <Container maxWidth="lg" sx={{p: {xs: 1, md: 0}, minHeight: "190px"}}>
                <Grid item xs={12}>
                    <Stack direction="column" sx={{ display: {xs: "none", sm: "flex"} }}>
                        <Box sx={{mx:"auto"}} justifyContent="center">{title}</Box>
                        <Box data-testid="subtitle-container">{subtitle}</Box>
                    </Stack>
                    <Stack spacing={1} direction="column" sx={{ display: {xs: "flex", sm: "none"} }}>
                        <Box sx={{mx:"auto"}} justifyContent="center">{title}</Box>
                        <Box>{subtitle}</Box>
                    </Stack>
                </Grid>
            </Container>
        </Box>
    )
}

export type SiteHeroProps = {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    bgColour: string;
    textColour: string;
}

export default SiteHero;