import React from 'react';
import Grid from '@mui/material/Grid';
import { useChildInfo } from './About';
import { MixedContentSection } from '../components';
import { PageInfoTypeSection } from '../data/page_data';
import Typography from '@mui/material/Typography';

export default function WhatsOnOffer() {

  const { childInfo } = useChildInfo();

  return (
    childInfo && (
      <Grid item container>
        <Typography variant="h4" flexGrow="1" sx={{ py: {sm: 1, md: 2} }} textAlign="center">{childInfo.title}</Typography>
        {childInfo !== null && childInfo.sections.map((section: PageInfoTypeSection, index: number) => {
          return <MixedContentSection key={section.id} sectionContent={section} flexDirection={index % 2 ? 'row' : 'row-reverse'} />
        })}
      </Grid>
    )
  )
}