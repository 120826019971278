import { Alert, Box, Button, Collapse, Grid, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

function ContactModal() {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [snackbarOpen, setsnackbarOpen] = useState<boolean>(false);
  const [submitResponse, setSubmitResponse] = useState<any>("");
  const handleClose = () => setOpen(false);

  const [formData, setFormData] = useState<any | null>({
    userFirstName: "",
    userSurname: "",
    emailAddress: "",
    type: "rock_school"
  });

  const [errors, setErrors] = useState<any>({
    userFirstName: {
      hasError: false,
      errorText: "Please enter your first name.",
    },
    userSurname: {
      hasError: false,
      errorText: "Please let us know your Surname",
    },
    emailAddress: {
      hasError: false,
      errorText: "Don't forget your email address otherwise we won't be able to get back to you!",
    }
  })

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): boolean => {
    if (event.target.value === ""){
      setErrors({
        ...errors,
        [event.target.name]: {
          hasError: true,
          errorText: errors[event.target.name].errorText,
          helperText: errors[event.target.name].helperText
        }
      });
    }else{
      setErrors({
        ...errors,
        [event.target.name]: {
          hasError: false,
          errorText: errors[event.target.name].errorText,
          helperText: errors[event.target.name].helperText
        }
      });
    }
    return false;
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [event.target.name]: event.target.value});
    setErrors({
      ...errors,
      [event.target.name]: {
        hasError: false,
        errorText: errors[event.target.name].errorText,
        helperText: errors[event.target.name].helperText
      }
    });
  }

  const checkFormIsValid = ():boolean => {
    return formData.userFirstName && 
      formData.userSurname && 
      formData.emailAddress
  }

  // const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   setsnackbarOpen(false);
  // };

  const submitForm = async () =>{
    let payload = formData;
    let requestOptions = {method: "POST", headers: {'content-type': 'application/json'}, body: JSON.stringify(payload)}
    let response = await fetch("https://www.thismusicmatters.co.uk/email/", requestOptions);
    let data:any = await response.json();
    setSubmitResponse(data);
    setsnackbarOpen(true);
    setFormData({
      userFirstName: "",
      userSurname: "",
      emailAddress: "",
      message: ""
    });
    setErrors({
      userFirstName: {
        hasError: false,
        errorText: "You haven't introduced yourself yet!",
      },
      userSurname: {
        hasError: false,
        errorText: "Please let us know your Surname",
      },
      emailAddress: {
        hasError: false,
        errorText: "Don't forget your email address otherwise we won't be able to get back to you!",
      }
    })
  }

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return(
  <Grid container>
    <Grid item>  
        <p>We now have a Rock School!<br></br> Click here to find out more!</p>
    </Grid>
    <Grid item alignContent="center" sx={{pl: 2}}>  
      <Button onClick={handleOpen} variant='contained'>Info</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            This Music Matters now has a ROCK SCHOOL!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please fill in the form below for more details.
          </Typography>
          <Stack direction={{ sm: "column", md: "row" }} spacing={2}>
            <TextField
              required
              name="userFirstName"
              label="First Name"
              sx={{ pb: 2 }}
              size="small"
              fullWidth
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              value={formData.userFirstName}
              error={errors.userFirstName.hasError}
              helperText={errors.userFirstName.hasError ? errors.userFirstName.errorText : errors.userFirstName.helperText}
            />
            <TextField
              required
              name="userSurname"
              label="Surname"
              sx={{ pb: 2 }}
              size="small"
              fullWidth
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              value={formData.userSurname}
              error={errors.userSurname.hasError}
              helperText={errors.userSurname.hasError ? errors.userSurname.errorText : errors.userSurname.helperText}
            />
          </Stack>
          <TextField
            required
            name="emailAddress"
            label="Email Address"
            sx={{pb: 2}}
            size="small"
            fullWidth
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={formData.emailAddress}
            error={errors.emailAddress.hasError}
            helperText={errors.emailAddress.hasError ? errors.emailAddress.errorText : errors.emailAddress.helperText}
          />
          <Grid item container justifyContent="flex-end" flexGrow={1} alignContent="flex-end">
            <Button disabled={!checkFormIsValid()} onClick={submitForm} variant="contained">Submit</Button>
          </Grid>
          <Collapse
            in={snackbarOpen}
            sx={{pt: 2}}
          >
            <Alert severity={submitResponse.id === 1 ? 'error' : 'success'} sx={{ width: '100%' }}>
            {submitResponse.message}
            </Alert>
          </Collapse>
        </Box>
      </Modal>
    </Grid>
  </Grid>
  )
}

export default ContactModal;