import React from 'react';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { srcset } from '../functions/image_functions';
import { useMediaQuery } from '@mui/material';
import theme from '../theme/theme_config';

function Gallery(props: GalleryProps) {
  const { title, images } = props;
  let isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack direction="column" justifyContent="space-evenly" alignItems="center" sx={{pt:0, mt:0}}>
      <Typography variant="h4" sx={{ pt: 4 }}>{title}</Typography>
      {isMobile ? (
        <ImageList
          sx={{ width: '100%' }}
          variant="quilted"
          cols={2}
          rowHeight={150}
        >
          {images.map((item) => (
            <ImageListItem key={item.image} cols={item.cols.mobile || 1} rows={item.rows.mobile || 1}>
              <img
                style={{objectFit: item.contain ? 'contain' : 'cover'}}
                {...srcset(item.image, 150, item.rows.mobile, item.cols.mobile)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <ImageList
          sx={{ width: '75%' }}
          variant="quilted"
          cols={4}
          rowHeight={200}
        >
          {images.map((item) => (
            <ImageListItem key={item.image} cols={item.cols.desktop || 1} rows={item.rows.desktop || 1}>
              <img
                style={{objectFit: item.contain ? 'contain' : 'cover'}}
                {...srcset(item.image, 200, item.rows.desktop, item.cols.desktop)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Stack>
  )
}

export type GalleryProps = {
  title: string;
  images: GalleryImageType[];
}

export type GalleryImageType = {
  image: string;
  title: string;
  contain: boolean;
  rows: {
    mobile: number;
    desktop: number;
  };
  cols: {
    mobile: number;
    desktop: number;
  };
}

export default Gallery;