import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { InfoCard, Pagination } from '../components';
import { PageInfoType } from '../data/page_data';
import { copyWithQuotes, getPage } from '../functions/text_functions';
import Paper from '@mui/material/Paper';
import TextList from '../components/TextList';

export default function Testimonials() {

  const [pageInfo, setPageInfo] = useState<PageInfoType | null>(null);

  const getListSections = (from: number) => {
    const items = pageInfo?.sections.filter((item, idx) => item.id > from);
    return items;
  }

  const itemCount = pageInfo?.sections.length ?? 0;

  useEffect(() => {
    const setupPage = async () => {
      const page = await getPage(3);
      setPageInfo(page);
    }

    setupPage();
  }, []);

  return (
    <Grid item container>
      {pageInfo?.sections.filter((item, idx) => idx < 3).map((section, index) => {
        return <InfoCard key={index} title={section.title} copy={copyWithQuotes(section.copy)} image={section.image ? section.image : null} icon={null} button={{}} />
      })}
      <Grid item xs={0} md={2}></Grid>
      <Grid item xs={12} md={8}>
        {itemCount > 3 ? 
        <Paper elevation={3} sx={{ py: 2, m: 4 }}>
          <Pagination collection={getListSections(3) ?? {}} options={{ allowItemsPerPage: true }}>
            <TextList />
          </Pagination>
        </Paper>
        : null}
      </Grid>
      <Grid item xs={0} md={2}></Grid>
    </Grid>
  )
}