import React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MMLogoRound from '../images/mm_logo_round.png';
import { ButtonDetailsType } from "../data/page_data";

function InfoCard(props: InfoCardProps){
  const {title, copy, image, icon, button} = props;

  return(
    <Grid item xs={12} md={4} flexGrow={1} sx={{ mb: 2 }}>
      <Card sx={{ position: 'relative', mx: 2, minHeight: {sm: 250, md: 450}, '&:hover': { transition: "transform 0.3s ease-in-out", transform: "scale3d(1.02, 1.02, 1)" }}}>
        <a className='card-link' href={button.link}>&nbsp;</a>
        <CardMedia
          component="img"
          sx={{height: {xs: 150, sm: 250}}}
          image={image !== null ? image : MMLogoRound}
          alt="Guitar and piano"
        />
        <Box sx={{px:2, pt:2}}>
          <Stack direction="row" spacing={2}>
            {icon}
            <Typography alignContent="center" variant="h5" lineHeight="1.1em" component="div">
              {title}
            </Typography>
          </Stack>
        </Box>
        <Typography sx={{p:2, minHeight:"100px"}} variant="body2" color="text.secondary" component="div">
          {copy}
        </Typography>
        {Object.keys(button).length > 0 && (
          <Grid item container justifyContent="flex-end">
              <CardActions sx={{pr:3}}>
                <Button variant="contained" href={button.link} size="small">{button.buttonText}</Button>
              </CardActions>
          </Grid>
        )}
      </Card>
    </Grid>
  )
}

export type InfoCardProps = {
    title: React.ReactNode;
    copy: React.ReactNode | string;
    image: string | null;
    icon: React.ReactNode;
    button: ButtonDetailsType;
    clickable?: boolean;
}
export default InfoCard;