import { alpha, styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

const NavButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.sitenav.text.primary,
    margin: "0 20px",
    '&:hover': {
        backgroundColor: alpha(theme.sitenav.text.hover, 1),
        color: alpha(theme.palette.text.primary, 1),
    },
    '&.Mui-active': {
        color: alpha(theme.sitenav.text.active, 0.5),
        '&::after': {
            height: "2px",
            borderTopRightRadius: "3px",
            borderTopLeftRadius: "3px",
            position: "absolute",
            right: "4px",
            bottom: "-5px",
            left: "4px",
            backgroundColor: theme.palette.background.default,
            content: '""',
        }
    },
})) as typeof Button;

export default NavButton;