import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { PageInfoType, PageInfoTypeSection, SubPageInfoType } from '../data/page_data';
import { getPage } from '../functions/text_functions';
import { Outlet, useLocation, useOutletContext, Link } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MixedContentSection } from '../components';

type ContextType = { childInfo: SubPageInfoType };

export default function About(props: any) {

  let location = useLocation();
  const theme = useTheme();
  let isMobile = useMediaQuery(theme.breakpoints.down('md'));
  let isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const [pageInfo, setPageInfo] = useState<PageInfoType | null>(null);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const [childInfo, setChildInfo] = useState<SubPageInfoType | null>(null);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const scrollIntoViewWithOffset = async (selector: any, offset: number) => {
    var element = document.querySelector(`#${selector}`);
    if (element !== null && element !== undefined) {
      window.scrollTo({
        behavior: 'smooth',
        top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
      });
    }
    return true;
  }

  const scrollToSection = useCallback(() => {
    scrollIntoViewWithOffset(location.state.scrollTo, 100)
      .then(() => setHasScrolled(true));
  }, [location.state]);

  const getChildInfo = React.useCallback((page: PageInfoType) => {
    var childObj: SubPageInfoType;
    if (page.children !== undefined && location.pathname.split('/').length > 2) {
      childObj = page?.children?.filter(child => child.ref === location.pathname.split('/')[2])[0];
      setChildInfo(childObj);
    }
  }, [location.pathname]);

  useEffect(() => {
    getPage(2)
      .then((page) => {
        setPageInfo(page);
        getChildInfo(page)
      })
      .then(async () => {
        if (location.state !== null && location.state !== undefined && !hasScrolled) {
          setTimeout(scrollToSection, 500);
        }
      }
      )
  }, [location.state, scrollToSection, getChildInfo, hasScrolled]);

  const randomColour = (obj: any) => {
    var keys = Object.keys(obj);
    return obj[keys[keys.length * Math.random() << 0]];
  };

  return (
    <Grid sx={{ pt: 2, mx: 'auto' }} maxWidth="lg" container direction="row" justifyContent="space-evenly" alignItems="stretch">
      <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} justifyContent="space-evenly" alignItems="stretch">
          
        {pageInfo?.cards?.map((card, index) => {
          return <Button 
            component={Link} 
            to={card?.button?.link ?? "/about"} 
            disableElevation key={index}
            variant={childInfo && card?.button?.link === location.pathname ? "contained" : "outlined"}
            size={isMobile ? "small": "large"}
          >
            {card.title}
          </Button>
        })}
        </Stack>
        <Stack direction={{ sm: "column", lg: "row" }} sx={{ pb: 4, mb: 2 }} spacing={0} alignItems="stretch" className="horizontal-divider">
          {pageInfo?.sections.filter((section, index) => index < 5).map((section, index) => {
            return (
            isTablet ? (
              <Accordion expanded={expanded === section.name} onChange={handleChange(section.name || '')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`"${section.name}bh-content"`}
                  id={`"${section.name}bh-header"`}
                >
                  <Typography>
                    {section.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {section.copy}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ) : (
              <Grid
                className={(index !== pageInfo.sections.length - 1) ? isMobile ? 'horizontal-divider ripple' : 'vertical-divider ripple' : 'ripple'}
                key={index}
                item
                textAlign="left"
                sx={{
                  p: { xs: 0, md: 2 },
                  mb: { xs: 0, md: 2 },
                  pb: { xs: 1, md: 0 },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: randomColour(theme.themecolours) }}
                >
                  {section.title}
                </Typography>
                <Typography variant="subtitle2" sx={{ pl: 1 }}>{section.copy}</Typography>
              </Grid>
            ))
          })}
        </Stack>     
      </Stack>
      <Grid item container sx={{ py: {sm: 1, md: 2} }}>
        {!childInfo && pageInfo?.sections.filter((section, index) => index > 4).map((section: PageInfoTypeSection, index: number) => {
          return <MixedContentSection key={section.id} sectionContent={section} flexDirection={index % 2 ? 'row' : 'row-reverse'} />
        })}
      </Grid>
      <Outlet context={{ childInfo }} />
    </Grid>
  )
}

export function useChildInfo() {
  return useOutletContext<ContextType>();
}